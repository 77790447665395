var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPendingSwapCompany },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.updateCurrentCompany },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "div",
        { staticClass: "d-flex justify-start" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "primary" },
              on: {
                click: function () {
                  this$1.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
        ],
        1
      ),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "white--text text-h5 mb-4",
                  attrs: { color: "secondary" },
                },
                [_vm._v(" User Edit ")]
              ),
              _vm._l(_vm.users, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "v-card",
                      { staticClass: "ma-5 pa-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start mx-2 mt-4" },
                          [
                            _c(
                              "p",
                              { staticClass: "text-h5 font-weight-bold" },
                              [_vm._v(" User " + _vm._s(index + 1) + " ")]
                            ),
                          ]
                        ),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c("v-col", [
                              _c("div", { staticClass: "px-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pr-3" },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "First Name",
                                            dense: "",
                                            outlined: "",
                                          },
                                          model: {
                                            value: item.user_name,
                                            callback: function ($$v) {
                                              _vm.$set(item, "user_name", $$v)
                                            },
                                            expression: "item.user_name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Last Name",
                                            dense: "",
                                            outlined: "",
                                          },
                                          model: {
                                            value: item.user_lname,
                                            callback: function ($$v) {
                                              _vm.$set(item, "user_lname", $$v)
                                            },
                                            expression: "item.user_lname",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Email",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: item.user_email,
                                        callback: function ($$v) {
                                          _vm.$set(item, "user_email", $$v)
                                        },
                                        expression: "item.user_email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Mobile Phone",
                                        dense: "",
                                        outlined: "",
                                      },
                                      model: {
                                        value: item.user_mobile,
                                        callback: function ($$v) {
                                          _vm.$set(item, "user_mobile", $$v)
                                        },
                                        expression: "item.user_mobile",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("v-col", { staticClass: "px-3" }, [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label: "Main Category",
                                      items: _vm.MainCategoryOptions,
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: item.user_subscription_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "user_subscription_type",
                                          $$v
                                        )
                                      },
                                      expression: "item.user_subscription_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Sub Category",
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: item.user_branch,
                                      callback: function ($$v) {
                                        _vm.$set(item, "user_branch", $$v)
                                      },
                                      expression: "item.user_branch",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label: "Role",
                                      items: _vm.RoleOptions,
                                      dense: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: item.user_role,
                                      callback: function ($$v) {
                                        _vm.$set(item, "user_role", $$v)
                                      },
                                      expression: "item.user_role",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex px-2" },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "AAE Role",
                                items: _vm.AAERoleOptions,
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: item.user_aae_role,
                                callback: function ($$v) {
                                  _vm.$set(item, "user_aae_role", $$v)
                                },
                                expression: "item.user_aae_role",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.checkIfCanAccessBonusOffer()
                          ? _c(
                              "div",
                              { staticClass: "d-flex px-2" },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    items: _vm.AccountUserOptions,
                                    label: "User Account Type",
                                  },
                                  model: {
                                    value: item.user_account_type,
                                    callback: function ($$v) {
                                      _vm.$set(item, "user_account_type", $$v)
                                    },
                                    expression: "item.user_account_type",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkIfCanAccessBonusOffer()
                          ? _c(
                              "div",
                              { staticClass: "d-flex px-2" },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    label: "User Bonus Offer",
                                  },
                                  model: {
                                    value: item.user_bonus_offer,
                                    callback: function ($$v) {
                                      _vm.$set(item, "user_bonus_offer", $$v)
                                    },
                                    expression: "item.user_bonus_offer",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex px-2" },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                items: _vm.ExistingClientOptions,
                                label: "Existing client ?",
                              },
                              model: {
                                value: item.existing_client,
                                callback: function ($$v) {
                                  _vm.$set(item, "existing_client", $$v)
                                },
                                expression: "item.existing_client",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex px-2" },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                items: _vm.ActiveOptions,
                                label: "Active",
                              },
                              model: {
                                value: item.user_active,
                                callback: function ($$v) {
                                  _vm.$set(item, "user_active", $$v)
                                },
                                expression: "item.user_active",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("ComponentCSField", {
                                  attrs: { fieldValue: item.user_cs_pic },
                                  on: {
                                    updateData: function (data) {
                                      item.user_cs_pic = data
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c("v-col", { staticClass: "pb-0" }, [
                              _vm.checkIfCanAccessOnboarding()
                                ? _c(
                                    "div",
                                    { staticClass: "px-2" },
                                    [
                                      _c("ComponentDateModal", {
                                        attrs: {
                                          label: "Welcome Guide Sent",
                                          data: item.welcome_guide_sent,
                                        },
                                        on: {
                                          updateDateData: function (data) {
                                            item.welcome_guide_sent = data
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { staticClass: "pt-0" }, [
                              _vm.checkIfCanAccessOnboarding()
                                ? _c(
                                    "div",
                                    { staticClass: "px-2" },
                                    [
                                      _c("ComponentDateModal", {
                                        attrs: {
                                          label: "Welcome WA Sent",
                                          data: item.welcome_wa_sent,
                                        },
                                        on: {
                                          updateDateData: function (data) {
                                            item.welcome_wa_sent = data
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex px-2" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                disabled: !_vm.$store.getters.getRole.includes(
                                  "developer"
                                )
                                  ? true
                                  : false,
                                label: "Referral Points",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: item.user_referral_points,
                                callback: function ($$v) {
                                  _vm.$set(item, "user_referral_points", $$v)
                                },
                                expression: "item.user_referral_points",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex px-2" },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                disabled: !_vm.$store.getters.getRole.includes(
                                  "developer"
                                )
                                  ? true
                                  : false,
                                items: _vm.userReferralOptions,
                                "item-text": "referral_uuid",
                                dense: "",
                                "return-object": "",
                                outlined: "",
                                "item-value": "referral_uuid",
                                label: "User Refer By",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.email) +
                                            " ( " +
                                            _vm._s(item.referral_uuid) +
                                            " ) "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "selection",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(" " + _vm._s(item.email) + " "),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.referral_by_user_uuid,
                                callback: function ($$v) {
                                  _vm.$set(item, "referral_by_user_uuid", $$v)
                                },
                                expression: "item.referral_by_user_uuid",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { staticClass: "mx-3" }, [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-switch", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm.isVerified[index] === true
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "text-h7 white--text",
                                                      attrs: { color: "green" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.isVerifiedCustomValue
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.isNotVerifiedCustomValue
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.isVerified[index],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.isVerified, index, $$v)
                                      },
                                      expression: "isVerified[index]",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-start" },
                                [
                                  _c("v-switch", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm.isEnable[index] === true
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "text-h7 white--text",
                                                      attrs: { color: "green" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.isEnableCustomValue
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.isNotEnableCustomValue
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.isEnable[index],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.isEnable, index, $$v)
                                      },
                                      expression: "isEnable[index]",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-end pa-5" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", plain: "" },
                      on: {
                        click: function () {
                          this$1.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function () {
                          this$1.isPending = true
                        },
                      },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }